.usecases {
  position: relative;
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
  /* padding-bottom: 100px; */
}

.usecases .scroll-button {
  display: flex;
  /* align-items: right;
  text-align: right; */
  justify-content: end;
  padding-bottom: 0px;

}

.usecases .scroll-button button {
  position: relative;
  padding: 10px 30px;
  background-color: #BE3030;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center; 
  height: 45px;
  font-size: 18px;
  font-weight: 700;
  border: solid 2px #BE3030;
  transition: .5s;
  border-radius: 5px;
  transition: 1s;
  z-index: 2;
}

.usecases .scroll-button button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0%;
  width: 100%;
  background-color: white;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: height 1s ease;
  border-radius: 5px;
}

.usecases .scroll-button button:hover::before {
  height: 100%;
  bottom: 0;
}

.usecases .scroll-button button:hover {
  border: solid 2px #BE3030;
  color: #BE3030;
  /* background: transparent; */
  transition: .5s;
}

.usecases .scroll-button button .arrow {
  margin-left: 10px;
}

.usecases .usecases-main-container {
  padding-top: 50px;
  /* padding-bottom: 100px; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.usecases .usecases-container {
  /* margin-top: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  gap: 30px;
}

.usecases .usecases-container:nth-child(even) {
  flex-direction: column-reverse; /* Reverse the layout for even-numbered boxes */
  justify-content: flex-end;
}

.usecases .usecases-container .top {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.usecases .usecases-container .top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  cursor: pointer;
  aspect-ratio: 1/1;
}

.usecases .usecases-container .bottom h1 {
  font-size: 25px;
  margin-top: 20px;
  text-transform: uppercase;
}

.usecases .usecases-container:nth-child(even) .bottom h1 {
  margin-top: 0px;
}

.usecases .usecases-container .bottom p {
  font-size: 16px;
  margin-top: 20px;
}

.usecases .overlay {
  position: absolute; /* Position overlay directly over the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0; /* Initially hidden */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  color: white;
  font-size: 1.5rem;
  transition: opacity 0.7s ease;
  border-radius: 20px; /* Match the image border radius */
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Show overlay on hover */

.usecases .usecases-container .top:hover .overlay {
  opacity: 1;
  z-index: 10;
}

/* Additional styling for the text inside the overlay */
.usecases .overlay .row{
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 100%; 
  padding: 30px;
  text-align: center; 
}

.usecases .overlay .name {
  font-size: 1rem;
  margin-top: 0px;
}

.usecases .overlay .play-button {
  height: 70px !important;
  width: 70px !important;
  /* margin-top: 10px !important;  */
}
 

  .usecases .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* border-radius: 30px; */
}

.usecases .video-overlay .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  pointer-events: none;
  z-index: 1111;
}

.usecases .video-player {
	position: fixed;
	/* top: 165px; */
	left: 0px;
	right: 0px;
	bottom: 50px;
	/* background-color: rgba(0, 0, 0, 0.8); */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1001;
  /* width: 100%;
  height: 100%; */
  }

  .usecases .video-player video{
    /* border-radius: 300px; */
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    z-index: 1001;
  }

  .usecases .video-player .video-content {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 1001;
    width: auto;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    text-align: left;
    padding: 30px;
    /* right: 150px; */
    right: 60%;
    /* top: 50%;
    bottom: 50%; */
    margin-left: 100px;
    margin-top: 150px;
  }

.usecases .video-player .video-content h1 {
  text-align: left;
  font-size: 35px;
}

.usecases .video-player .video-content h3 {
  font-size: 25px;
  margin-top: 20px;
}

.usecases .video-player .video-content p {
  margin-top: 30px;
}

.usecases .close-button1 {
	position: absolute;
	bottom: 10px;
	/* right: .5rem; */
	background-color: white;
	color: white;
	/* padding: 14px 25px; */
	font-size: 16px;
	font-weight: 500;
	border-radius: 50%;
	border: 0;
	outline: 0;
	/* padding: 0.5rem 1rem; */
	border: none;
	/* border-radius: 0.5rem; */
	cursor: pointer;
	width: 70px;
  z-index: 1004;
  padding: 5px;
  }
 
  .usecases .close-button1:hover {
	/* background: white; */
	/* color: #BE3030; */
	/* transform: scale(1.1) ; */
	transition: 0.3s;
	
  }

@media screen and (max-width: 1800px) {
  .usecases {
    padding-top: 100px;
    padding-left: 200px;
    padding-right: 200px;
    /* padding-bottom: 100px; */
  }

  .usecases .usecases-main-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .usecases .usecases-container {
    width: 500px;
    gap: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .usecases {
    padding-left: 50px;
    padding-right: 50px;
  }

  .usecases .scroll-button {
    padding-right: 50px;
  }

  .usecases .usecases-container {
    width: 100%;
  }

  .usecases .video-player .video-content {
    margin-right: 100px;
  }

.usecases .video-player .video-content h1 {
  text-align: left;
  font-size: 35px;
}

.usecases .video-player .video-content h3 {
  font-size: 25px;
  margin-top: 20px;
}

.usecases .video-player .video-content p {
  margin-top: 30px;
}
}


@media screen and (max-width: 950px) {

  .usecases .video-player .video-content h1 {
    font-size: 25px;
  }
  
  .usecases .video-player .video-content h3 {
    font-size: 20px;
  }
  
  .usecases .video-player .video-content p {
    margin-top: 30px;
  }
}

@media screen and (max-width: 850px) {
  .usecases .video-player .video-content {
    margin-right: 50px;
  }
}

@media screen and (max-width: 768px) {
  .usecases {
    padding-left: 20px;
    padding-right: 20px;
  }

  .usecases .video-player .video-content {
    justify-content: left;
    justify-items: left;
    text-align: left;
    padding: 20px;
    /* right: 150px; */
    left: auto;
    right: auto;
    top: 150px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .usecases .usecases-container .bottom {
    font-size: 25px;
    text-align: center;
  }

  .usecases .usecases-container:nth-child(even) {
    flex-direction: column; /* Reverse the layout for even-numbered boxes */
    justify-content: flex-end;
  }

  .usecases .video-player .video-content h1 {
    font-size: 20px;
  }

  .usecases .video-player .video-content h3 {
    display: none;
  }

  .usecases .video-player .video-content p {
    display: none;
  }
}

@media screen and (max-width: 700px) {

  .usecases {
    padding-left: 70px;
    padding-right: 70px;
  }

  .usecases .usecases-main-container {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 80px;
  }

  .usecases .usecases-container {
    gap: 20px;
  }

  .usecases .scroll-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-left: 25px;
  }
  
  .usecases .scroll-button button {
    position: relative;
    padding: 10px 30px;
    background-color: #BE3030;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 45px;
    font-size: 18px;
    font-weight: 700;
    border: solid 2px #BE3030;
    transition: .5s;
    border-radius: 5px;
    transition: 1s;
  }
}

@media screen and (max-width: 550px) {
  .usecases {
    padding-left: 30px;
    padding-right: 30px;
  }

  .usecases .video-player .video-content {
    top: 150px;
    padding: 15px;
  }

  .usecases .video-player .video-content h1 {
    font-size: 16px;
  }

  .usecases .scroll-button button {
    width: 250px;
  }
}