.navbar nav {
    width: 100%;
    height: 70px;
    /* background: rgba(190, 48, 48, 0.5); */
    color: white;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    position: fixed; /* Ensure the navbar is always fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1002; /* Ensure it stays on top */
    padding: 10px 0; /* Adjust padding as necessary */
    overflow: hidden;
    /* margin-top: -10px; */
}

.navbar nav .right{
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.navbar .dark-nav {
    /* background: rgba(190, 48, 48, 0.1); */
    /* background: rgba(190, 48, 48, .8);  */
    background: white;
    color: black;
}

.navbar .dark-nav ul li a:hover,
.navbar .dark-nav ul li a:focus,
.navbar .dark-nav ul li a.active {
    color: #BE3030; /* Change the text color on hover or active */
}

.navbar .dark-nav ul li a:hover::after,
.navbar .dark-nav ul li a:focus::after,
.navbar .dark-nav ul li a.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px; /* Position underline below the text */
    width: 100%;
    height: 2px;
    background-color: #BE3030; /* Underline color */
    transition: width 0.3s ease-in-out; /* Smooth transition for the underline */
}

.navbar .dark-nav ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: #BE3030;
    transition: width 0.3s ease-in-out; /* Animate the underline width on hover */
}

.navbar .navbar-ul li a:hover,
.navbar .navbar-ul li a:focus,
.navbar .navbar-ul li a.active {
    color: #BE3030; /* Change the text color on hover or active */
}

.navbar .navbar-ul li a:hover::after,
.navbar .navbar-ul li a:focus::after,
.navbar .navbar-ul li a.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px; /* Position underline below the text */
    width: 100%;
    height: 2px;
    background-color: #BE3030; /* Underline color */
    transition: width 0.3s ease-in-out; /* Smooth transition for the underline */
}

.navbar .navbar-ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: #BE3030;
    transition: width 0.3s ease-in-out; /* Animate the underline width on hover */
}

.navbar .btn1 {
    /* background: #BE3030; */
    position: relative;
    color: white;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 25px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 80px;
    border: solid 2px white;
    transition: .5s;
}   

.navbar .btn1:hover {
    /* background-color: #BE3030; */
    color: white;
    /* border: solid 2px #BE3030; */
    cursor: pointer;
    /* box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%); */
    transition: .5s;
  }

  .navbar .btn1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0%;
    width: 100%;
    background-color: #BE3030;
    transform: translate(-50%, 0);
    z-index: -1;
    transition: height 1s ease;
    border-radius: 5px;
  }
  
  .navbar .btn1:hover::before {
    height: 100%;
    bottom: 0;
  } 

  .navbar .logo {
    width: 150px;
    margin-left: 20px;
    margin-top: 0px;
    transition: .5s;
}

.navbar .black-logo {
    width: 150px;
    margin-left: 20px;
    margin-top: 0px;
    transition: .5s;
}

.navbar nav ul {
    /* align-items: center;
    justify-items: center;
    justify-content: center; */
    align-content: center;
}

.navbar nav ul li {
    display: inline-block;
    list-style: none;
    margin: 3px 15px;
    font-size: 14px;
    font-weight: 600;
}

.navbar .flag {
    width: 30px;
    /* margin-left: 0px; */
}

.navbar .flag:hover {
    transform: scale(1.1);
}

.navbar .phone-number {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
}
.navbar .phone-number:hover {
    color: #BE3030;
}

/* .dark-nav .phone-number:hover {
    color: black;
} */

.navbar nav ul li a {
    text-decoration: none; /* Remove default underline */
    color: inherit; /* Inherit text color */
    position: relative;
}

.navbar nav ul li a:hover,
.navbar nav ul li a:focus,
.navbar nav ul li a.active {
    color: #BE3030; /* Change the text color on hover or active */
}

.navbar nav ul li a:hover::after,
.navbar nav ul li a:focus::after,
.navbar nav ul li a.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px; /* Position underline below the text */
    width: 100%;
    height: 2px;
    background-color: #BE3030; /* Underline color */
    transition: width 0.3s ease-in-out; /* Smooth transition for the underline */
}

.navbar nav ul li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: #BE3030;
    transition: width 0.3s ease-in-out; /* Animate the underline width on hover */
}

/* Dropdown styling */
.navbar .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    /* left: 0; */
    background-color: white;
    padding: 5px 0;
    list-style: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: -70px;
  }
  
.navbar .dropdown-menu .dropdown-item {
    padding: 8px 15px;
    color: black;
    white-space: nowrap;
  }

  .navbar .dropdown-menu .dropdown-item a {
    font-size: 14px;
  }

  .navbar .dropdown-menu .dropdown-icon {
    font-size: large;
    /* margin-top: 10px; */
  }
  /* .dropdown-item:hover {
    color: #BE3030;
  } */

  /* Mobile Dropdown Menu */

  .navbar .navbar-ul .dropdown-menu-mobile {
    /* display: flex; */
    /* flex-direction: column; */
    /* position: absolute; */
    /* top: 100px; */
    right: 270px;
    background-color: white;
    padding: 10px 0;
    list-style: none;
    /* border-radius: 5px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    margin-top: 60px;
    z-index: 1;
    width: 250px;
    /* height: 250px; */
  }

  .navbar .dropdown-menu-mobile .dropdown-item {
    padding: 0px 0px;
    color: black;
    /* white-space: nowrap; */
  }

.navbar .flag-number {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    justify-content: center;
}

.navbar .flag-number-mobile {
    display: none; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
}

.navbar .toggle {
    display: none;
}

.navbar .menuIcon {
    display: none;
}

.navbar .btn2 {
    position: relative;
    background: #BE3030;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 25px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-left: 20px;
    border: 2px solid #BE3030;
    transition: .3s;
    overflow: hidden;
    z-index: 2;
}

.navbar .btn2:hover {
    color: #BE3030;
    border: 2px solid #BE3030;
    text-decoration: none;
    transition: .3s;
}

.navbar .btn2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0%;
    width: 100%;
    background-color: white;
    color: #BE3030;
    transform: translate(-50%, 0);
    z-index: -1;
    transition: height 1s ease;
  }
  
  .navbar .btn2:hover::before {
    height: 100%;
    bottom: 0;
  }  

/* .navbar .btn2 a:hover {
    color: #BE3030 !important
} */

.navbar .btn1 a:hover::after,
.navbar .btn1 a:active::after,
.navbar .btn1 a:focus::after {
    text-decoration: none !important
}

.navbar .btn-mobile {
    display: none;
}

.navbar .social-links {
    display: none;
}

.navbar .mail-phone-mobile {
    display: none;
}


@media screen and (max-width: 1330px) {
    .navbar nav ul li {
        margin: 10px 15px;
    }

    .navbar .flag, .navbar .phone-number {
        display: none;
    }
    .navbar ul {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1200px) {

    .navbar nav ul {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        background: white; 
        width: -300px;
        padding-top: 70px;
        /* transition: 0.5s; */
        margin-top: -10px;
        overflow-y: scroll;
    }

    .navbar nav ul li {
        display: block;
        margin: 25px 20px;
        color: #000;
    }

    .navbar .dark-nav ul li a:hover,
    .navbar .dark-nav ul li a:focus,
    .navbar .dark-nav ul li a.active {
        color: #BE3030; /* Change the text color on hover or active */
    }
    
    .navbar .dark-nav ul li a:hover::after,
    .navbar .dark-nav ul li a:focus::after,
    .navbar .dark-nav ul li a.active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px; /* Position underline below the text */
        width: 100%;
        height: 2px;
        background-color: #BE3030; /* Underline color */
        transition: width 0.3s ease-in-out; /* Smooth transition for the underline */
    }
    
    .navbar .dark-nav ul li a::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 0;
        height: 2px;
        background-color: #BE3030;
        transition: width 0.3s ease-in-out; /* Animate the underline width on hover */
    }

    .navbar nav {
        padding: 15px 0;
        margin: 0;
    }

    .navbar .logo {
        /* width: 220px; */
        margin-left: 0px;
    }

    .navbar .black-logo {
        /* width: 220px; */
        margin-left: 0px;
    }

    .navbar .navbar {
        margin: 0;
    }

    .navbar nav ul {
        margin-right: 0;
        width: 300px;
    }
    /* Hide these elements on larger screens */
    .navbar .btn1, .navbar .btn2{
        display: none;
    }

    /* Display flag, phone number, and button below mobile menu */
    .navbar .btn-mobile {
        display: block;
        margin: 15px 20px;
        color: white;
        text-align: center;
    }

    .navbar .flag-number .flag, .navbar .flag-number .phone-number {
        display: block;
    }

    .navbar .flag-number {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px; /* Adjust as necessary */
        padding-right: 10px; /* Adjust as necessary */
    }

    .navbar .flag-mobile {
        width: 30px;
    }

    .navbar .btn-mobile {
        padding: 14px 20px;
        border-radius: 30px;
        background: #BE3030;
        color: white;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
    }

    .navbar .btn-mobile:hover {
        background-color: black;
        color: white;
    }

    .navbar .dark-nav .btn-mobile {
        background: #BE3030;
        color: white;
    }

    .navbar .dark-nav .btn-mobile:hover {
        background-color: black;
        color: white;
    }

    .navbar nav .menuIcon {
        margin-right: 50px;
        display: block;
        width: 35px;
        cursor: pointer;
        z-index: 10;
    }

    .navbar .hide-mobile-menu {
        margin-right: -300px;
    }

    .navbar .mail-phone-mobile {
        display: block;
        margin-left:20px;
        margin-top: 50px;
    }

    .navbar .mail-phone-mobile li {
        display: flex;
        align-items: center;
        margin: 20px 0;
      }

    .navbar .mail-phone-mobile .side-logo {
        margin-top: 20px;
        margin-left: 0px;
        width: 200px;
    }

    .navbar .mail-phone-mobile img {
        width: 25px;
        margin-right: 10px;
      }
    .navbar .mail-phone-mobile p {
        margin-top: 10px;
        font-size: 14px;
        color: black;
      }

    .navbar .social-links {
        margin-left: -20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 5px 0 5px 0;
        padding-bottom: 30px;
      }
      
      .navbar .social {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        font-size: 40px;
        margin-left: 15px;
        cursor: pointer;
        z-index: 1000;
      }
}

/* @media screen and (max-width: 960px) {
    .navbar .flag-number {
        display: none;
    }
} */

@media screen and (max-width: 768px) {
    .navbar .flag-number .flag, .navbar .flag-number .phone-number {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .navbar .logo {
        width: 120px;
        margin-left: 10px;
    }

    .navbar .black-logo {
        width: 120px;
        margin-left: 10px;
    }

    .navbar .menuIcon {
        width: 22px;
        margin-right: -10px;
    }
}

@media screen and (max-width: 530px) {
    .navbar .navbar-ul .dropdown-menu-mobile {
        display: none;
    }

    .navbar .navbar-ul .dropdown-icon {
        display: none;
    }
    /* .navbar nav .menuIcon {
        margin-right: 40px;
    } */
}