/* Testimonial container */
.testimonial-container {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  /* max-width: 1000px; */
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 100vh; */
  padding-left: 100px;
  padding-right: 100px;
  overflow: hidden;
}

.testimonial-container .title {
  text-align: center;
  margin-bottom: 2rem;
}

.testimonial-container .title h1 {
  text-transform: capitalize;
  font-weight: bold;
  /* font-size: 30px; */
  line-height: 1.1;
}

.testimonial-container .title h3 {
  font-size: 20px;
  color: #BE3030;
  font-weight: 600;
}

/* Slider container */
.testimonial-container .slider-container {
  /* margin-top: 50px; */
  margin-bottom: 50px;
  position: relative;
  user-select: none;
  padding-inline: 1rem;
}

.testimonial-container .slider-container .quote {
  position: absolute;
  z-index: 1;
}

.testimonial-container .slider-container .top-quote {
  position: absolute;
  top: -2rem;
  left: -1rem;
  width: 100px;
}

.testimonial-container .slider-container .bottom-quote {
  position: absolute;
  bottom: 1rem;
  right: -1rem;
  transform: rotate(180deg);
  width: 100px;
}

.testimonial-container .slider-container .review-img {
  width: 600px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  margin-inline: auto;
}

.testimonial-container .slider-container .review-img img {
  width: 100%;
  height: 100%;
}

/* Splide customization */
.testimonial-container .splide__track {
  border-radius: 1rem;
  padding-bottom: 50px;
}

.testimonial-container .splide__slide {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* background: gray; */
  padding: 3rem 1.875rem;
  border-radius: 1rem;
  gap: 1rem;
  border: none;
}

/* .slider-container .content{
  padding: 20px 20px 20px 20px;
} */

.testimonial-container .slider-container .content .head {
  color: #BE3030;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.testimonial-container .slider-container .content .text {
  color: #484646;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.testimonial-container .slider-container .content .rating {
  display: inline-block;
  line-height: 1;
}

.testimonial-container .slider-container .content .star {
  color: #BE3030;
  font-size: 1.1rem;
}

.testimonial-container .slider-container .content .company {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #BE3030;
}

.testimonial-container .slider-container .content .user {
  font-weight: 600;
}

.testimonial-container .splide__pagination__page.is-active {
  background-color: #BE3030;
}

.testimonial-container .splide__arrow {
  position: absolute;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  /* color: #505050; */
  font-weight: 300;
}

.testimonial-container .splide__arrow--prev {
  top: 50%;
  left: -3.5rem;
}

.testimonial-container .splide__arrow--next {
  top: 50%;
  right: -3.5rem;
}

.testimonial-container .splide__pagination__page {
  height: 10px;
  width: 10px;
  opacity: 1;
}

/* Media Query */

@media (max-width: 1600px) {
  .testimonial-container {
    max-width: 1000px;
  }
}

@media (max-width: 900px) {
  .testimonial-container .splide__slide {
    display: block;
    text-align: center;
  }

  .testimonial-container .splide__arrow {
    font-size: 1.5rem;
  }

  .testimonial-container .slider-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .testimonial-container .slider-container .top-quote, .testimonial-container .slider-container .bottom-quote {
    width: 100px;
  }


  .testimonial-container .slider-container .content .text {
    font-size: 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonial-container .slider-container .content .user {
    font-weight: 600;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonial-container .slider-container .review-img {
    width: 100px;
  }
  
  .testimonial-container .slider-container .review-img img {
    width: 100%;
    height: 100%;
  }

  /* .splide__arrow--prev {
    left: 0.5rem;
  }

  .splide__arrow--next {
    right: 0.5rem;
  } */
}



@media (max-width: 678px) {
  .testimonial-container {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .testimonial-container .slider-container .content{
    padding: 0px;
  }

  .testimonial-container .splide__slide {
    padding-top: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testimonial-container .slider-container .top-quote {
    width: 60px;
    left: 1rem;
    top: -1.5rem;
  }

  .testimonial-container .slider-container .bottom-quote {
    right: 1rem;
    width: 60px;
    bottom: 3rem;
  }
  .testimonial-container .splide__pagination__page {
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .testimonial-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .testimonial-container .splide__arrow {
    display: none;
  }
}