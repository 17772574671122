.navbar-thankyou nav {
    width: 100%;
    height: 70px;
    background: white;
    color: black;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    /* margin-top: -10px; */
    overflow: hidden;
}

.navbar-thankyou .dark-nav {
    /* background: rgba(190, 48, 48, 0.1); */
    background: white;
}

.navbar-thankyou .btn1 {
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 25px;
    border-radius: 30px;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}   

.navbar-thankyou .btn1:hover {
    background-color: #BE3030;
    cursor: pointer;
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  }

.navbar-thankyou .logo {
    margin-left: 30px;
    width: 250px;
}

@media screen and (max-width: 1100px) {
    .navbar-thankyou nav {
        padding: 15px 0;
        margin: 0;
    }

    .navbar-thankyou .navbar {
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .navbar-thankyou .logo {
        width: 220px;
        margin-left: 10px;
    }

    .navbar-thankyou .btn2 {
        font-size: 15px;
        padding: 15px;
        margin-right: 20px;
    }

}