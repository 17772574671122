.industries {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  overflow: hidden;
}

.industries .heading-div {
  margin-left: -100px;
}

.recognition-head {
    /* margin-top: 50px; */
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.recognition-head h3 {
    font-size: 25px;
    color: #BE3030;
    font-weight: 700;
}

.recognition-head h1 {
    margin-top: 10px;
    font-size: 40px;
    font-weight: 800;
}

.recognition-head p {
    margin-top: 10px;
}

.recognition-head span {
    color: #BE3030;
}

/* General container for recognitions */

.recognition-body{
    margin-top: 50px;
}

.recognition-body ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 items in a row for desktop */
    gap: 20px;
    list-style: none;
    padding: 0;
  }
  
  .recognition-body li {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .recognition-body li img {
    width: 35px; /* Adjust image size */
    height: auto;
    margin-right: 10px; /* Space between image and text */
  }
  
  .recognition-body li {
    font-weight: 700;
    color: #333;
    font-size: 14px;
  }
  
.recognition-bottom {
    margin-top: 50px;
    margin-bottom: 30px;
}

.recognition-bottom img {
  width: 50px;
}

.recognition-bottom ul {
    /* display: grid; */
    display: flex;
    justify-content: center; /* Center the list items */
    align-items: center;
}

.recognition-bottom li {
    padding: 30px;
  }

.recognition-bottom h1 {
    color: #BE3030;
    font-size: 40px;
    font-weight: 800;
}

.recognition-bottom h4 {
    margin-top: 10px;
}

.recognition-bottom h1 span {
    display: inline-block; /* Ensure it's treated as a block-level element for rotation */
    animation: flip 2s linear infinite;
  }
  
  .industries .btn.button-touch2 {
    position: relative;
    padding: 10px 30px;
    background-color: #BE3030;
    border: 2px solid #BE3030;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center; /* Aligns text and line vertically */
    height: 45px;
    font-size: 18px;
    font-weight: 700;
    margin-top: 50px;
    z-index: 2;
    overflow: hidden;
    transition: 1s;
  }

  .industries .btn.button-touch2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0%;
    width: 100%;
    background-color: white;
    transform: translate(-50%, 0);
    z-index: -1;
    transition: height 1s ease;
    border-radius: 5px;
  }
  
  .industries .btn.button-touch2:hover::before {
    height: 100%;
    bottom: 0;
  }
  
  .industries .btn.button-touch2 .line {
    margin-left: 10px;
    position: relative;
    width: 70px; /* Fixed width for the line */
    height: 2px;
    background-color: white;
  }
  
  .industries .btn.button-touch2 .circle {
    position: absolute;
    top: -3px; /* Position the circle to align with the middle of the text */
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    animation: moveCircle 4s linear infinite;
  }
  
  .industries .btn.button-touch2:hover {
    /* background: white; */
    color: #BE3030;
    border: solid 2px #BE3030;
  }
  
  .industries .btn.button-touch2:hover .line {
    background: #BE3030;
    color: #BE3030;
  }
  
  .industries .btn.button-touch2:hover .circle {
    background: #BE3030;
    color: #BE3030;
  }
  
  @keyframes moveCircle {
    0% {
      left: 0;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 0;
    }
  }
  
  /* Keyframes for rotation */
  @keyframes flip {
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateX(360deg);
    }
  }

  /* Responsive Styles */
  @media (max-width: 1200px) {
    .recognition-body ul {
      grid-template-columns: repeat(5, 1fr); /* 3 items in a row for tablets */
    }
  }
  
  @media (max-width: 1000px) {
    .recognition-body ul {
      grid-template-columns: repeat(4, 1fr); /* 2 items in a row for smaller screens */
    }
    .recognition-head h1 {
      font-size: 35px;
    }
  }
  
  @media (max-width: 850px) {
    .recognition-body ul {
      grid-template-columns: repeat(3, 1fr); /* 1 item in a row for mobile */
    }
    .recognition-head h1 {
      font-size: 30px;
    }
    .recognition-head h3 {
      font-size: 20px;
    }

    .recognition-bottom li {
      padding: 20px;
    }
  }
  
  @media (max-width: 650px) {
    .recognition-body ul {
      grid-template-columns: repeat(2, 1fr); /* 1 item in a row for mobile */
    }
    .recognition-bottom h1 {
      font-size: 30px
    }
    .recognition-bottom img {
      width: 40px;
    }
  }

  @media (max-width: 450px) {
    .industries {
      padding-top: 50px;
      /* padding-bottom: 100px; */
    }
    .recognition-body ul {
      gap: 10px;
    }

    .recognition-body li img {
      width: 25px;
    }

    .recognition-body li {
      font-weight: 600;
      font-size: 12px;
    }
    .recognition-bottom h1 {
      font-size: 25px
    }
    .recognition-bottom h4 {
      font-size: 14px;
    }
    .recognition-bottom img {
      width: 30px;
    }

  }


@media (max-width: 400px) {
  .recognition-body ul {
    grid-template-columns: repeat(2, 1fr); /* 1 item in a row for mobile */
  }

  .recognition-body ul {
    gap: 8px;
  }

  .recognition-body li img {
    width: 20px;
  }

  .recognition-body li {
    font-weight: 600;
    font-size: 12px;
  }

  .recognition-bottom ul {
    display: block;
  }
}

@media (max-width: 360px) {
  .industries .btn.button-touch2 {
    padding: 8px 20px;
    height: 45px;
    font-size: 16px;
  }
}

@media (max-width: 340px) {
  .recognition-body ul {
    grid-template-columns: repeat(1, 1fr); /* 1 item in a row for mobile */
  }
}