.caseStudy {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
}

.caseStudy .swiper_container {
    height: 24rem;
    position: relative;
}

.caseStudy .swiper-button-prev,
.caseStudy .swiper-button-next {
    color: #BE3030;
    font-size: 10px;
    opacity: 0.7;
    transition: color 0.3s ease, opacity 0.3s ease;
}

.caseStudy .swiper-button-prev:hover,
.caseStudy .swiper-button-next:hover {
    color: black;
    opacity: 1;
}

.caseStudy .flexSB {
    padding-left: 80px;
    padding-right: 80px;
    /* margin-top: 50px; */
    display: flex;
    justify-content: space-between;
    gap: 80px;
}

.caseStudy .left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.caseStudy .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Vertically centers the content */
    align-items: flex-start; /* Left-aligns the content */
    width: 100%; /* Ensure full width of the right div */
}

.caseStudy .aboutHeading {
    text-align: left;
    /* max-width: 300px; */
}

.caseStudy .aboutHeading .top {
    display: flex;
    justify-content: left;
    align-items: center;
}

.caseStudy .aboutHeading .top h2 {
    /* margin-left: 10px; */
    color: #BE3030;
    font-size: 30px;
    font-weight: 800;
}

.caseStudy .aboutHeading .top img {
    /* margin-left: -20px; */
    width: 130px;
}

.caseStudy .aboutHeading p {
    margin-top: 20px;
    font-size: 18px;
    /* max-width: 300px; */
    padding-right: 50px;
}



.caseStudy .youtube-box {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    height: auto;
    cursor: pointer;
  }


  .caseStudy .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    transition: opacity .7s ease;
    border-radius: 5px;
    z-index: 1;
    height: 100%;
    pointer-events: none;
}

.caseStudy .overlay svg {
    font-size: 50px;
}

.caseStudy .youtube-box:hover .overlay {
    cursor: pointer;
    opacity: .6;
    background-color: black;
}

.caseStudy .youtube-box img {
    width: 100%; /* Full width */
    height: 100%;
}
  
.caseStudy  .video-player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Aspect ratio for 16:9 */
  }
  
.caseStudy  .video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
.caseStudy .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px 5px;
    cursor: pointer;
    font-size: 20px;
  }
  

.caseStudy .left, .caseStudy .right {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  width: 100%; /* Ensure full width */
}

@media(max-width: 1300px) {
    .caseStudy .swiper_container {
        height: 40rem;
    }
    .caseStudy .flexSB {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .caseStudy .right {
        align-items: center; 
        margin-top: -50px;
    }

    .caseStudy .aboutHeading .top {
        justify-content: center;
        gap: 0px;
    }

    /* .caseStudy .aboutHeading .top img {
        margin-right: 0px;
        display: none;
    } */

    .caseStudy .aboutHeading p {
        padding-right: 0px;
    }

    .caseStudy .aboutHeading h2, 
    .caseStudy .aboutHeading p {
        /* padding: 20px; */
        text-align: center;
    }

    .caseStudy .aboutHeading p {
        max-width: 700px;
    }

    .caseStudy .right {
        padding-top: 30px;
    }
}

@media screen and (max-width: 880px) {
    .caseStudy .swiper-button-prev,
    .caseStudy .swiper-button-next {
        display: none;
    }
}

@media screen and (max-width: 800px) {
  .caseStudy {
    padding-top: 50px;
  }
  .caseStudy .aboutHeading .top {
    gap: 50px;
}
.caseStudy .right {
    margin-top: 20px;
}

.caseStudy .aboutHeading .top img {
    width: 100px;
}

  .caseStudy .swiper_container {
    height: 38rem;
}

.caseStudy .swiper-button-prev,
.caseStudy .swiper-button-next {
    color: #BE3030;
    font-size: 2px;
    opacity: 0.7;
    transition: color 0.3s ease, opacity 0.3s ease;
}

    .caseStudy .flexSB {
        flex-direction: column;
        align-items: center;
        text-align: center;
          padding-left: 50px;
          padding-right: 50px;
          gap: 20px;
    }

    .caseStudy .left, .right {
        width: 100%;
        text-align: center;
        align-items: center;
    }

    .caseStudy .aboutHeading {
      padding-top: 0px;
    }

    .caseStudy .aboutHeading h2, 
    .caseStudy .aboutHeading p {
        /* padding: 20px; */
        text-align: center;
    }
    .caseStudy .aboutHeading p {
      font-size: 16px; /* Decrease font size for readability on smaller screens */
  }

    .caseStudy .youtube-box {
      width: 100%; /* Full width for mobile */
    }

    .caseStudy .youtube-box .video {
      margin-bottom: 150px;
      width: 100%; /* Full width on mobile */
      height: 100%; /* Maintain aspect ratio */
  }
}

@media screen and (max-width: 650px) {
    .caseStudy .swiper_container {
        height: 35rem;
    }
}

@media screen and (max-width: 600px) {
    .caseStudy {
        padding-top: 30px;
    }
    .caseStudy .swiper_container {
        /* margin-top: 50px; */
        padding-bottom: 0px;
    }
    .caseStudy .flexSB {
        gap: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .caseStudy .aboutHeading p {
        font-size: 16px;
    }
    .caseStudy .swiper-button-prev,
    .caseStudy .swiper-button-next {
    color: #BE3030;
    font-size: 5px;
    opacity: 0.7;
    transition: color 0.3s ease, opacity 0.3s ease;
}
}


@media screen and (max-width: 550px) {
    .caseStudy .swiper_container {
        margin-top: -30px;
        height: 32rem;
    }
    .caseStudy .right {
        margin-top: -40px;
    }

    .caseStudy .aboutHeading .top {
        gap: 10px;
    }

    .caseStudy .aboutHeading .top h2 {
        margin-left: -10px;
        font-size: 25px;
    }
    .caseStudy .aboutHeading .top img {
        margin-left: 0px;
    }

    /* .caseStudy .swiper-button-prev,
    .caseStudy .swiper-button-next {
        display: none;
    }  */

    .caseStudy .flexSB {
        padding-left: 10px;
        padding-right: 10px;
    }

    .caseStudy .youtube-box {
        margin-top: 0px;
        max-width: 100%;   /* Ensure the container doesn’t overflow */
        margin: 0 auto;    /* Center the video container */
      }
    
      /* Make the ReactPlayer video responsive */
      .caseStudy .youtube-box .video {
        margin-bottom: 0px;
        width: 100% !important;   /* Make the video take up the full width */
        height: auto !important;  /* Adjust height automatically to maintain aspect ratio */
        border-radius: 10px;      /* Optional: Match the container's rounded corners */
      }
}  

@media screen and (max-width: 450px) {
    .caseStudy .swiper_container {
        height: 32rem;
    }  
    .caseStudy .aboutHeading .top img {
        width: 70px;
    }
    .caseStudy .aboutHeading .top h2 {
        margin-left: 0px;
    }
}

@media screen and (max-width: 400px) {
    .caseStudy .flexSB {
          gap: 50px;
    }
    .caseStudy .swiper_container {
        margin-top: -50px;
        height: 33rem;
    }
}

@media screen and (max-width: 350px) {
    .caseStudy .swiper_container {
        height: 34rem;
    }
}

@media screen and (max-width: 300px) {
    .caseStudy .swiper_container {
        height: 36rem;
    }
}