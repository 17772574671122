.footer {
  width: 100%;
  position: absolute;
  /* bottom: 0; */
  /* background: linear-gradient(rgb(255, 0, 0, .1), rgba(255, 0, 0, .1)), url("../../Assets/Common/SkyLineDubai.jpg"); */
  background-position: center;
  background-size: cover;
  background: white;
  color: black;
  /* padding-top: 50px; */
  /* border-top-left-radius: 125px; */
  font-size: 13px;
  line-height: 20px;
  /* margin-top: 100px; */
  overflow: hidden;
}

footer hr {
  width: 1000px;
  border: .5px solid black;
  margin: auto;
}

footer .row{
  padding-top: 50px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  }

footer .col {
  flex-basis: 18%;
  padding: 20px;
}

footer .col:nth-child(1) {
  /* flex-basis: 100%; */
  display: flex;
  flex-direction: column; 
  align-items: center;
  /* align-content: center;  */
  /* justify-content: center; 
  justify-items: center;
  text-align: center; */
}

/* footer .col:nth-child(2), footer .col:nth-child(3) {
  flex-basis: 15%;
} */

footer .col .logo {
  width: 250px;
  margin-top: -30px;
  /* margin-left: -150px; */
}

footer .col .logo-details {
  margin-top: 0px;
  /* text-align: left; */
  max-width: 600px;
}

/* footer .col .logo-contact {
  margin-top: 0px;
} */

footer .col .clutch-review {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 10px;
  margin-left: -10px;
}

footer .col .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

footer .col .left img {
  width: 80px;
  margin-left: 10px;
}

footer .col .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

footer .col .right .rating {
  display: inline-block;
  line-height: 1;
}

footer .col .right .star {
  color: #BE3030;
  font-size: 1rem;
}

footer .col ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-align: left;
}

footer .col ul li a {
  display: flex;
  align-items: center;
  margin: 3.5px 0;
  text-align: left;
  transition: .5s;
}

footer .col .phone-icon, footer .col .mail-icon {
  width: 30px;
}

footer .col ul li img {
  width: 25px;
  margin-right: 10px;
}

footer .col h3{
  width: fit-content;
  margin-bottom: 40px;
  /* position: relative; */
  /* display: flex; */
  align-items: left;
  text-align: left;
  /* justify-content: space-between; */
}

footer .underline {
  margin-top: -20px;
  /* flex-grow: 1; */
  width: 100%;
  height: 5px;
  background: #BE3030;
  border-radius: 3px;
  position: relative;
  top: 25px;
  left: 0;
}

footer .underline span{
  width: 15px;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 10px;
  animation: moving 2s linear infinite;
}

footer ul li a:hover,
footer ul li a:focus,
footer ul li a.active {
  color: #BE3030; /* Change the text color on hover or active */
}

@keyframes moving {
  0% {
    left: 20%;
  }
  100% {
    left: 100%;
  }
}

.footer .footer-top {
  display: block;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding-bottom: 30px;
}

.footer .footer-top .details {
  /* margin-top: 20px; */
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  /* max-width: 700px; */
}

.footer .footer-top .chat-on {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-items: center;
  margin-top: 30px;
  border: 3px solid black;
  border-radius: 5px;
}

.footer .footer-top .chat-on .chat-on-div{
  padding: 10px 50px 10px ;
  font-weight: 700;
  font-size: 18px;
  transition: .3s;
}

.footer .footer-top .chat-on .chat {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.footer .footer-top .chat-on .chat img{
  width: 30px;
}

.footer .footer-top .chat-on .chat svg {
  margin-bottom: -2px;
}

.footer .footer-top .chat-on .whatsapp:hover {
  color: #50cd5e;
  transition: .3s;
}

.footer .footer-top .chat-on .live-chat:hover {
  color: #BE3030;
  transition: .3s;
}

.footer .footer-bottom {
  background: #BE3030;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 10px 15px;
}

.footer .footer-bottom  .copyRight  {
  /* padding-left: 300px; */
  /* padding: 10px 0 10px; */
  /* text-align: center; */
  font-weight: 700;
  color: white;
  /* background: rgba(0, 0, 0, 0.3); */
}

.footer .footer-bottom  .policy  {
  /* padding-right: 300px; */
  /* padding: 10px 0 10px; */
  /* text-align: center; */
  font-weight: 700;
  /* background: rgba(0, 0, 0, 0.3); */
}

@media (max-width: 1300px) {
}

@media (max-width: 1150px) {
  footer .col:nth-child(1) {
    flex-basis: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    /* align-content: center;  */
    /* justify-content: center; 
    justify-items: center;
    text-align: center; */
  }
  footer .col .logo-details {
    text-align: center;
    max-width: 600px;
  }
}

@media (max-width: 880px) {
  .footer .footer-bottom  .copyRight  {
    padding-left: 50px;
  }
  
  .footer .footer-bottom  .policy  {
    padding-right: 50px;
  }
}

@media (max-width: 768px) {
  footer .col:nth-child(2), footer .col:nth-child(3) {
    flex-basis: 50%;
  }

  footer .col:nth-child(4), footer .col:nth-child(5) {
    flex-basis: 50%;
  }


  .footer .footer-top .details {
    font-size: 14px;
    max-width: 400px;
  }

  .footer .footer-bottom {
    display: block;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .footer .footer-bottom  .copyRight  {
    padding-left: 0px;
  }
  
  .footer .footer-bottom  .policy  {
    padding-right: 0px;
  }
}


@media (max-width: 750px) {
  footer {
    bottom: unset;
  }

  .footer .footer-top .chat-on .chat-on-div{
    padding: 10px 20px 10px ;
  }

  .footer .footer-bottom p  {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .footer .footer-top .chat-on .chat {
    display: none;
  }

  .footer .footer-top .chat-on .chat-on-div{
    padding: 10px 10px 10px ;
    font-size: 16px;
  }

  footer .col.rnc-details-col {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
}

@media (max-width: 350px) {
  footer .col:nth-child(2), footer .col:nth-child(3), footer .col:nth-child(4),footer .col:nth-child(5) {
    flex-basis: 100%;
  }
  footer .col .logo-details {
    text-align: left;
  }

  footer .col .logo-contact {
    margin-right: auto;
  }
}