.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  background: rgba(0, 0, 0, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
  overflow: hidden;
}

.modal-content {
  position: fixed;
  right: 100px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px 30px 30px;
  border-radius: 8px;
  width: auto;
  height: auto;
  display: block;
  gap: 30px;
  transition: .5s;
}

/* .height {
  height: 540px;
} */

.modal-content .closeButton {
  position: absolute; /* Position it inside the modal content */
  top: 15px; /* Adjust distance from the top of modal-content */
  right: 15px; /* Adjust distance from the right of modal-content */
  width: 15px;
  cursor: pointer; /* Add a pointer to enhance UX */
}

.modal-content .left,
.modal-content .right {
  /* width: 50%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content .left img {
  width: 400px;
  margin-top: 20px;
}

/* .modal-content .left p {
  margin-top: 20px;
} */

.contact-col {
  margin-top: 30px;
}

.contact-col .head {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact-col .head img {
  width: 70px;
  height: 100%;
}

.contact-col h1 {
  text-align: center;
  font-size: 20px;
  max-width: 350px;
  /* margin-left: auto;
  margin-right: auto; */
}

.contact-col h1 .color {
  font-size: 25px;
  color: #BE3030;
}

.contact-col form {
  margin-top: 30px;
  /* margin-bottom: 30px; */
  align-items: center;
  justify-content: center;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.contact-col form input, .contact-col form select, .contact-col form option {
  display: block;
  width: 100%;
  background: white;
  padding: 15px;
  border: 0;
  border-radius: 10px;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
  font-size: 14px;
}

.contact-col form input::placeholder {
  color: gray;
}

.contact-col form select {
  /* color: gray; */
  padding-left: 10px;
  padding-right: 10px;
}

.contact-col form .phone-input {
  display: block;
  width: 100%;
  background: white;
  padding: 6px 10px 6px;
  border: 0;
  border-radius: 10px;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
  font-size: 14px;
}

.contact-col form label {
  text-align: left; /* Align labels to the left */
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.contact-col .result {
  display: flex;
  /* margin: 20px 0; */
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.recaptcha-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
}

.recaptcha {
  margin-left: auto;
  margin-right: auto;
}

.btn.button-touch3 {
  margin-top: 30px !important;
  /* margin-bottom: 30px; */
  position: relative;
  padding: 10px 30px;
  background-color: #BE3030;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  border-radius: 10px;
  margin: 0 auto;
}

.btn.button-touch3 .line {
  margin-left: 10px;
  position: relative;
  width: 50px;
  height: 2px;
  background-color: white;
}

.btn.button-touch3 .circle {
  position: absolute;
  left: 0;
  margin-top: -3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  animation: moveCircle 4s linear infinite;
}

.btn.button-touch3:hover {
  background: white;
  color: #BE3030;
}

.btn.button-touch3:hover .line {
  background: #BE3030;
}

.btn.button-touch3:hover .circle {
  background: #BE3030;
}

@media only screen and (max-width:990px) {
  .modal-content {
    position: fixed;
    right: auto;
  }
}

@media only screen and (max-width:768px) {
  .modal-content {
    display: block;
    height: auto;
    /* margin-top: 200px; */
    width: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
  }

  /* .contact-col {
    margin-top: 0px;
  } */

  .contact-col h1 {
    text-align: center;
  }
  
  .contact-col form {
    width: 350px;
    /* padding-bottom: 30px; */
  }

  .modal-content .left,
  .modal-content .right {
    width: 100%;
  }

  .modal-content .left img {
    /* margin-top: 30px; */
    width: 300px;
  }

  .modal-content .left p {
    display: none;
  }

  .modal-content .right {
    margin-top: 0px;
  }
  .btn.button-touch3 {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width:500px) {
  .modal-overlay {
    background: rgba(0, 0, 0, .5);
    /* padding-left: 100px; */
    /* padding-right: 100px; */
  }

  .modal-content {
    margin-top: 50px;
    padding: 20px;
    width: 350px;
    height: auto;
  }

  /* .modal-content .left img {
    display: none;
  } */

  .contact-col .head img {
    display: none;
  }

  .contact-col {
    padding-top: 30px;
  }

  .contact-col form {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    width: 280px;
  }

  .contact-col h1 {
    font-size: 18px;
  }
  
  .contact-col h1 .color {
    font-size: 22px;
  }

  .recaptcha-div {
    width: 300px; /* Adjust width as needed */
    transform: scale(0.8); /* Scale down */
    transform-origin: 80px;
  }
}

@media only screen and (max-width:370px) {
  .modal-content {
    width: 300px;
  }
  .contact-col form {
    width: 260px;
  }
  /* .contact-col h1 {
    padding-left: 40px;
    padding-right: 40px;
  } */
  .modal-content .left img {
    display: none;
  }

  .recaptcha-div {
    transform-origin: 40px;
  }
}