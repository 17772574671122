.process-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding-top: 100px;
    padding-bottom: 50px;
}

.process-container .title {
    font-size: 25px;
}

.process-container .process-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 50px;
    justify-content: center;
}

/* .process-container .process-grid .process-grid-card:nth-last-child(2) {
    grid-column: 2 / 3; Place the first item of the last row in the 2nd column
}

.process-container .process-grid .process-grid-card:nth-last-child(1) {
    grid-column: 3 / 4; Place the second item of the last row in the 3rd column
} */

.process-container .process-grid .process-grid-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /* background: #b63030; */
    /* box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2); */
    padding: 30px;
    border-radius: 5px;
}

.process-container .process-grid .si-no {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 18px;
    font-weight: 800;
}

.process-container .process-grid .process-image {
    width: 200px;
    /* height: 300px; */
}

.process-container .process-grid .process-image video {
    width: 100%;
    height: 100%;
}

.process-container .process-grid .process-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.process-container .process-grid .process-content h1 {
    font-size: 20px;
    text-transform: uppercase;
}

.process-container .process-grid .process-content p {
    font-size: 15px;
}

@media screen and (max-width: 1300px) {
    .process-container .process-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .process-container .process-grid .process-image {
        width: 250px;
        /* height: 200px; */
    }
}

@media screen and (max-width: 1100px) {
    .process-container.container1 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .process-container .process-grid .process-grid-card {
        padding: 20px;
    }

    .process-container .process-grid .process-image {
        width: 200px;
        /* height: 200px; */
    }
}

@media screen and (max-width: 800px) {
    .process-container .process-grid .process-image {
        width: 200px;
        /* height: 200px; */
    }

    .process-container .process-grid .process-content h1 {
        font-size: 18px;
        text-transform: uppercase;
    }
    
    .process-container .process-grid .process-content p {
        font-size: 14px;
    }
}

@media screen and (max-width: 700px) {
    .process-container .process-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .process-container .process-grid .si-no {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 18px;
        font-weight: 800;
    }
}

@media screen and (max-width: 400px) {
    .process-container.container1 {
        padding-left: 20px;
        padding-right: 20px;
    }
}