.pagenotfound {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full viewport height */
    text-align: center;
    overflow: hidden;
}

.pagenotfound .notfound img {
    width: 500px;
    height: auto;
}

.pagenotfound .notfound h1 {
    font-size: 30px;
    padding: 30px;
}

@media screen and (max-width: 768px) {
    .pagenotfound .notfound img {
        width: 300px;
        height: auto;
    }
    .pagenotfound .notfound h1 {
        font-size: 18px;
        padding: 20px;
    }
}

@media screen and (max-width: 500px) {
    .pagenotfound .notfound img {
        width: 200px;
        height: auto;
    }
}