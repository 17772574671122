.WhatWeDo {
    padding-top: 100px;
    /* background: linear-gradient(180deg, black, #BE3030);s */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    color: black;
    overflow: hidden;
}

.WhatWeDo .heading-div {
	margin-left: -100px;
}

.WhatWeDo .textContainer {
    flex: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 50px;
    margin-right: -100px;
}

.WhatWeDo .textContainer p {
    font-weight: 500;
    font-size: 18px;
    color: #BE3030;
    text-align: right;
}

.WhatWeDo .textContainer hr {
    width: 300px;
    border: none;
    border: 0.5px solid #BE3030;
}

.WhatWeDo .titleContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    gap: 20px;

}

.WhatWeDo .titleContainer .title {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 20px;
}

/* .WhatWeDo .titleContainer .title-2 {
    margin-top: -20px;
} */

.WhatWeDo .titleContainer .title img {
    width: 200px;
    height: 75px;
    border-radius: 50px;
    object-fit: cover;
}

.WhatWeDo .titleContainer .title h1 {
    font-size: 40px;
    font-weight: 700;
}

.WhatWeDo .titleContainer .title .color {
    /* font-weight: 800; */
    color: #BE3030;
}

.WhatWeDo .titleContainer .title button {
    position: relative;
    width: 180px;
    height: 50px;
    border-radius: 50px;
    background: url("../../../Assets/Bg/3d.webp");
    object-fit: cover;
    background-position: center;
    background-size: cover;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.WhatWeDo .titleContainer .title .what-we-do {
    /* background: url("../../../Assets/Bg/2d-1.jpg"); */
    object-fit: cover;
    background-position: center;
    background-size: cover;
}

.WhatWeDo .titleContainer .title button::before {
    content: '';
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5); /* Black overlay with 50% opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
}

.WhatWeDo .titleContainer .title button span {
    position: relative;
    font-weight: 700;
    z-index: 2; /* Ensure the text is above the overlay */
}

.WhatWeDo .listContainer {
    flex: 2;
    display: flex;
    max-width: 1366px;
    margin: auto;
    /* align-items: center; */
    /* padding-bottom: 100px; */
    /* gap: 10px; */
    margin-top: 30px;
    margin-bottom: 50px;
}

.WhatWeDo .listContainer .box {
    padding: 30px;
    /* border:  2px solid black; */
    display: flex;
    flex-direction: column;
    /* align-items: top; */
    /* justify-content: space-between; */
    border-radius: 5px;
}

.WhatWeDo .listContainer .arrow {
    width: 70px;
    height: 70px;
    margin-top: 150px;
    /* margin-right: 10px; */
}

.WhatWeDo .listContainer .arrow-down {
    /* margin-left: 50px; */
    width: 100px;
    margin: 0 auto;

}

/* .WhatWeDo .listContainer .arrow2 {
    margin-top: 20px;
} */

.WhatWeDo .listContainer hr {
    border: 1px solid black;
}

.WhatWeDo .listContainer .box:not(:last-child) {
    border-right: none;
}

.WhatWeDo .listContainer .box h1 {
    font-size: 25px;
    color: #BE3030;
}

.WhatWeDo .listContainer .box ul {
    margin-top: 20px;
    /* margin-left: 10px; */
}

.WhatWeDo .listContainer .box ul li {
    list-style: square inside;
    padding-top: 5px;
}

.WhatWeDo .listContainer .box button {
    padding: 10px;
    background-color: transparent;
    bottom: none;
    cursor: pointer;
    margin-top: 20px;
    border: 2px solid #BE3030;
    border-radius: 5px;
}

@media (max-width: 1150px) {
    .WhatWeDo.container1 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .WhatWeDo .listContainer .box {
        padding: 20px;
    }
}

@media (max-width: 1000px) {
    .WhatWeDo .titleContainer .title img {
        width: 150px;
        height: 50px;
    }

    .WhatWeDo .titleContainer .title h1 {
        font-size: 40px;
    }

    .WhatWeDo .listContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .WhatWeDo .listContainer .box:not(:last-child) {
        /* border-right: 2px solid black; */
    }

    .WhatWeDo .listContainer .box:not(:last-child) {
        border-bottom: none;
    }

    .WhatWeDo .listContainer .box ul li {
        list-style: none;
    }

    .WhatWeDo .listContainer .arrow {
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 768px) {
    .WhatWeDo {
        margin-bottom: 0px;
    }
}

@media (max-width: 700px) {
    .WhatWeDo .titleContainer .title img {
        display: none;
    }
}

@media (max-width: 600px) {
    .WhatWeDo .listContainer {
        text-align: center;
    }
}

@media (max-width: 450px) {
    .WhatWeDo.container1 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .WhatWeDo .titleContainer {
        padding-bottom: 0px;
    }

    .WhatWeDo .titleContainer .title h1 {
        font-size: 30px;
    }

    .WhatWeDo .listContainer .box {
        padding: 20px;
    }

    .WhatWeDo .listContainer hr {
        display: none;
    }

    /* .WhatWeDo .listContainer {  
    } */

    .WhatWeDo .listContainer .box {
        /* border:  2px solid black; */
        border-radius: 5px;
    }
}

@media (max-width: 350px) {
    .WhatWeDo .titleContainer .title {
        gap: 10px;
    }

    .WhatWeDo .titleContainer .title img {
        width: 130px;
        height: 40px;
    }
}