.aboutHome { 
  padding-top: 50px;
  /* height: auto; */
  padding-bottom: 50px;
  overflow: hidden;
}

.aboutHome .heading-div {
margin-left: -100px;
}

.aboutHome .flexSB {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
}

.aboutHome .left {
/* background-color: rgba(255, 255, 255, 0.3); */
border-radius: 10px;
/* padding: 30px; */
width: 100%;
display: flex;
justify-content: center;
justify-items: center;
align-items: center;
text-align: left;
}

.aboutHome .right {
background: white;
border-radius: 10px;
padding: 10px;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2);
/* width: 80%;
height: 80%; */
}

.aboutHome .right iframe {
border: 0px;   /* Remove border for iframe inside the 'right' div */
padding: 20px; /* Adjust padding for better appearance */
}

.aboutHome .aboutHeading {
  /* margin-top: 30px; */
  padding: 0;
  color: black;
  /* padding: 40px 0; */
  /* text-align: left;
  justify-content: center;
  justify-items: center;
  align-items: center; */
}

.aboutHome .aboutHeading h1 {
font-size: 40px;
}

.aboutHome .aboutHeading h1 span{
color: #BE3030;
}

.aboutHome .aboutHeading h3{
  color: #BE3030;
  font-size: 25px;
  font-weight: 800;
  margin-top: 40px;
  /* text-align: center; */
  max-width: 100%;
}

.aboutHome .aboutHeading h2 {
  margin-top: 20px;
  font-size: 30px;
  /* text-align: center; */
}

.aboutHome .aboutHeading p {
  /* max-width: 450px; */
  margin-top: 20px;
  padding-right: 10px;
  /* font-weight: 600; */
}

.aboutHome .right img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

@media(max-width: 1265px){
  .aboutHome.aboutHeading h3, .aboutHome .aboutHeading p {
    max-width: 400px;
    /* padding-right: 30px; */
  }
  .aboutHome .items{
    display: none;
  }
  .aboutHome .right {
    padding: 20px;
  }
}

@media screen and (max-width: 1320px) {
  .aboutHome .right iframe {
    height: 595px;
  }
}

@media screen and (max-width: 950px) {
  .aboutHome {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0px;
  }
  .aboutHome .flexSB {
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-items: center;
      margin-top: 0px;
  }

  .aboutHome .left, .right {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
  }

  .aboutHome .left {
    padding: 0px;
    padding-left: 0px;
    padding-right: 10px;
  }

  .aboutHome .right {
    padding-left: 20px;
    padding-right: 20px;
  }

    .aboutHome .right iframe {
      height: 610px;
    }

  .aboutHome {
      flex-direction: column-reverse;
      padding-left: 20px;
  }

  .aboutHome .aboutHeading {
    margin-top: 0px;
  }

  .aboutHome .aboutHeading h1 {
    font-size: 30px;
  }

  .aboutHome .aboutHeading h3 {
    margin-top: -20px;
  }
  .aboutHome .aboutHeading h2 {
    margin-top: -20px;
  }
  .aboutHome .aboutHeading p {
    margin-top: -20px;
  }

  .aboutHome .aboutHeading h1, .aboutHome .aboutHeading h2, .aboutHome .aboutHeading p, .aboutHome .aboutHeading h3 {
      max-width: 100%;
      text-align: center;
      padding: 20px;
      align-items: center;
  }
  .aboutHome .right{
      padding: 10px;
  }
}

@media screen and (max-width: 580px) {
.aboutHome .right iframe {
  height: 760px;
}
}

@media screen and (max-width: 500px) {
  .aboutHome .flexSB {
    padding-left: 10px;
    padding-right: 10px;
  }
  .aboutHome .right {
    padding: 10px;
  }
  /* .aboutHome .right iframe {
    padding: 15px;
    height: 585px;
  } */
  .aboutHome .aboutHeading h2 {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .aboutHome {
    padding-left: 10px;
    padding-right: 10px;
    /* margin-top: -50px; */
  }
}

/* @media screen and (max-width: 362px) {
  .aboutHome .right iframe {
    height: 611.5px;
  }
} */