.awards {
  padding-top: 50px;
  padding-bottom: 100px;
  overflow: hidden;
}


.awards .heading-div {
  margin-left: -100px;
}

.awards .awards-body {
    margin-top: 50px;
    flex-wrap: wrap;
    justify-items: center;
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}


.awards .awards-image img {
    width: 110px;
}

.awards .awards-image .award-image-1 {
  width: 100px;
}

.awards .awards-image .award-image-4 {
    width: 150px;
}

/* Default arrow styling */
/* .awards .custom-arrow {
    background-color: #BE3030 !important;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  /* Style specifically for next and prev */ 
 /* .next-arrow {
    right: -30px; 
  }
  
  .prev-arrow {
    left: -40px;
  } */
  
  /* Optional: Add hover effects */
  /* .awards .custom-arrow:hover {
    background-color: #a02020 !important;
  } */

  @media screen and (max-width: 1200px) {
    .awards .awards-body {
      grid-template-columns: repeat(3, 1fr);
  }
  .awards .awards-image img {
      width: 100px;
  }
  .awards .awards-image .award-image-1 {
    width: 90px;
  }
  
  .awards .awards-image .award-image-4 {
      width: 130px;
  }
} 

@media screen and (max-width: 600px) {
  .awards .awards-body {
    margin-top: 50px;
  }
  .awards .awards-body {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}
  .awards .awards-image img {
    width: 100px;
  }
}  

@media screen and (max-width: 500px) {
  .awards {
    padding-top: 0px;
  }
  .awards .awards-body {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
    gap: 30px;
}
  .awards .awards-image img {
    width: 100px;
  }
}  