.quick-contact {
  /* margin-top: 100px; */
  /* background: rgba(0, 0, 0, 0.3); */
  background: url("../../../Assets/Bg/contactbg.webp");
  object-fit: cover;
  background-position: center;
  background-size: cover;
  color: white;
  display: flex; 
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
}

.quick-contact .overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
z-index: 0;
}

.quick-contact .contact-details {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* margin-top: 30px; */
/* margin-left: -50px; */
gap: 50px;
z-index: 1;
}

.quick-contact .contact-details .contact-phone,
.quick-contact .contact-details .contact-chat,
.quick-contact .contact-details > div {
display: flex;
align-items: center;
gap: 10px; 
}

.quick-contact .contact-details img {
width: 50px;
}

.quick-contact .contact-details a:hover {
color: #BE3030;
transition: 0.5s;

}

.quick-contact .contact-details .contact-phone, .contact-chat {
display: flex;
align-items: center;
font-size: 25px;
font-weight: 700;
}

.quick-contact .contact-details .contact-phone .phoneIcon {
transition: 0.3s;
}

.quick-contact .contact-details .contact-phone .phoneIcon:hover {
transform: rotate(45deg);
transition: 0.3s;
}

.quick-contact .contact-details .contact-chat .chatIcon {
transition: 1s;
}

.quick-contact .contact-details .contact-chat .chatIcon:hover {
transform: rotate(360deg);
transition: 1s;
}

.quick-contact .contact-details span {
font-size: 15px;
font-weight: bold;
}

.quick-contact .contact a:hover {
color: #BE3030;
}

/* .quick-contact .contact-details .chatIcon:hover {
transform: rotate(360deg); 
transition: 1s;
} */

.quick-contact .btn.button-touch2 {
position: relative;
padding: 10px 30px;
background-color: transparent;
border: 2px solid white;
color: white;
cursor: pointer;
display: flex;
align-items: center; /* Aligns text and line vertically */
height: 45px;
font-size: 18px;
font-weight: 700;
transition: 1s;
}

.quick-contact .btn:hover {
/* background-color: #BE3030; */
color: #fff;
cursor: pointer;
box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
transition: 1s;
}

.quick-contact .btn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0%;
  width: 100%;
  background-color: #BE3030;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: height 1s ease;
  border-radius: 5px;
}

.quick-contact .btn:hover::before {
  height: 100%;
  bottom: 0;
}

.quick-contact .btn.button-touch2 .line {
margin-left: 10px;
position: relative;
width: 70px; /* Fixed width for the line */
height: 2px;
background-color: white;
}

.quick-contact .btn.button-touch2 .circle {
position: absolute;
top: -3px; /* Position the circle to align with the middle of the text */
left: 0;
width: 8px;
height: 8px;
border-radius: 50%;
background-color: white;
animation: moveCircle 4s linear infinite;
}

/* .quick-contact .btn.button-touch2:hover {
background: white;
color: #BE3030;
}

.quick-contact .btn.button-touch2:hover .line {
background: #BE3030;
color: #BE3030;
}

.quick-contact .btn.button-touch2:hover .circle {
background: #BE3030;
color: #BE3030;
} */

@keyframes moveCircle {
0% {
  left: 0;
}
50% {
  left: 100%;
}
100% {
  left: 0;
}
}

@media screen and (max-width: 1000px) {
.quick-contact .contact-details {
  gap: 20px;
}

.quick-contact .contact-details .contact-phone, .contact-chat {
  font-size: 20px;
}

.quick-contact .contact-details span {
  font-size: 12px;
}
}

@media screen and (max-width: 850px) {
.quick-contact {
  padding-top: 30px;
  padding-bottom: 30px;
}

.quick-contact .contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
}

@media only screen and (max-width: 550px) {
.quick-contact .container1 {
    padding-left: 20px;
    padding-right: 20px;
}

/* .quick-contact-container {
    padding-left: 0px;
    padding-right: 0px;
} */

.quick-contact .contact-details .contact-phone, .contact-chat {
  font-size: 16px;
}

.quick-contact .contact-details span {
  font-size: 12px;
}

.quick-contact .contact-details .phoneIcon, .quick-contact .contact-details .chatIcon {
    width: 40px;
}
}

@media only screen and (max-width: 450px) {
.quick-contact .btn.button-touch2 {
  padding: 10px 20px;
  font-size: 16px;
}

.quick-contact .btn.button-touch2 .line {
  width: 50px;
}
}