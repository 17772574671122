.services {
  background: url("../../../Assets/Bg/3d.webp");
  object-fit: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
  color: #fff;
  height: 100vh;
  display: block;
  align-content: center;
  overflow: hidden;
  scroll-snap-type: none;
  scroll-snap-align: none;
}

.services .heading-div {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  margin-top: 100px;
}

.services .heading-div h1 hr {
  border: 1px solid white; /* Adjust border thickness and color */
}

.services .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

/* .services .services-content {
  display: flex;
  gap: 50px;
} */

.services .services-content .top {
  flex: 1;
  align-content: center;
}

.header-container {
  display: flex;
  align-items: center; /* Aligns items vertically centered */
}

.services .services-content .top h1 {
  font-size: 40px;
  font-weight: 700;
}

.services .services-content .top .service-types {
  width: 1000px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* display: block;
  align-items: center;
  justify-content: center; */
  /* text-align: center; */
  
}

.services .services-content .top .types h1 {
  font-size: 60px;
  cursor: pointer;
}

.services .services-content .top  p {
  margin-top: 20px;
  max-width: 800px;
}

.services .services-content .top  p span {
  font-weight: 700;
}

.services .services-content .top .btn {
  position: relative;
  margin-top: 30px;
  width: 300px;
  background: transparent;
  color: #fff;
  border: solid 2px white;
  transition: .5s;
  cursor: pointer;
  font-weight: 800;
  overflow: hidden;
}

.services .services-content .top .btn:hover {
  /* background-color: #BE3030; */
  /* color: black; */
  transition: .5s;
}

.services .services-content .top .btn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0%;
  width: 100%;
  background-color: #BE3030;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: height 1s ease;
  border-radius: 5px;
}

.services .services-content .top .btn:hover::before {
  height: 100%;
  bottom: 0;
} 

.services .services-content .bottom-overlay {
  position: absolute;
  height: 100px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .8);
  z-index: 1;
}

.services .splide {
  position: absolute;
  bottom: -80px;
  /* display: block; */
  /* align-items: center;
  justify-content: center;
  justify-items: center; */
  align-content: center;
  /* width: 50%; */
  z-index: 2;
  overflow: hidden;
  width: 100%;
}

.services .splide img {
  width: 220px;
  /* margin-right: 20px; */
}

@media (max-width: 1000px) {
  .services .services-content .top .service-types {
    width: 800px;
  }
}
@media (max-width: 800px) {
  .services .splide img {
    width: 190px;
  /* margin-right: 20px; */
  }
}

@media (max-width: 800px) {
  .services {
    padding-left: 20px;
    padding-right: 20px;
  }
  .services .splide img {
    width: 170px;
  }

  .services .splide {
    bottom: -50px;
    left: -5px;
  }
  .services .services-content {
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .services .services-content .top.container1 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .services .services-content .top h1 {
    font-size: 30px;
    font-weight: 700;
  }
  
  .services .services-content .top .service-types {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .services .services-content .top .types{
    display: flex;
    justify-items: center;
  }
  
  .services .services-content .top .types h1 {
    font-size: 40px;
  }

  .services .services-content .top .btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 700px) {
  .services .services-content .top .service-types {
    width: 550px;
  }
}

@media (max-width: 560px) {

  .services .services-content .top .service-types {
    width: 400px;
  }  
  
  .services .services-content .top .types h1 {
    font-size: 30px;
  }

  .services .splide {
    bottom: -60px;
  }

  .services .splide img {
    width: 190px;
  }
}

@media (max-width: 460px) {
  .services .services-content .top .service-types {
    width: 350px;
  } 
}

@media (max-width: 360px) {
  .services .services-content .top .service-types {
    width: 320px;
  } 
}

@media (max-width: 330px) {
  .services .services-content .top .service-types {
    width: 280px;
  } 
}