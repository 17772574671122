.footer1 {
    width: 100%;
    position: absolute;
    bottom: 0;
    /* background: linear-gradient(rgb(255, 0, 0, .1), rgba(255, 0, 0, .1)), url("../../Assets/Common/SkyLineDubai.jpg"); */
    background-position: center;
    background-size: cover;
    color: black;
    /* padding-top: 50px; */
    border-top-left-radius: 125px;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
  }
  
.footer1 .copyRight  {
    padding: 10px 0 10px;
    text-align: center;
    font-weight: 700;
    background: white;
    color: black;
  }
  
  @media (max-width: 700px) {
    /* footer {
      bottom: unset;
    } */
  }